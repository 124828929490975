<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <PageHeader :items="items" />

    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div
            v-if="
              login_type == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-2"
            style="width: 12%"
          >
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getAllCities()"
            ></multiselect>
          </div>
          <!--              @input="getAllCompanies()"-->

          <!-- Country List END-->
          <!-- Cities List-->
          <div
            v-if="
              login_type == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-2"
            style="width: 12%"
          >
            <label>City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getAllCompanies()"
            ></multiselect>
          </div>
          <!--          @input="readRestaurantBranchData()"-->
          <!--END Cities List-->
          <!-- Company List-->
          <div
            v-if="
              login_type == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-2"
            style="width: 16%"
          >
            <label>Company</label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              :show-labels="false"
              label="companyName"
              track-by="companyID"
              @input="getRestaurantBrandsByCompanyID()"
            ></multiselect>
          </div>
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div
            v-if="
              login_type == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-2"
            style="width: 16%"
          >
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              :show-labels="false"
              label="name"
              track-by="restID"
              @input="readRestaurantBranchData()"
            ></multiselect>
          </div>
          <!--              @input="getRestaurantCities()"-->
          <!-- Brand List END-->
          <!-- Branch List-->
          <div
            v-if="
              login_type == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11 ||
                this.$storageData.profile.empTypeID == 9
            "
            class="col-md-2"
            style="width: 16%"
          >
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              label="branchName"
              track-by="restBranchID"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- for principles -->
          <div
            v-if="
              login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18 ||
                (this.$storageData.profile.empTypeID == 8 &&
                  login_type == 4)
            "
            class="col-md-2"
            style="width: 12%"
          >
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getPrincipleCities()"
            ></multiselect>
          </div>
          <div
            v-if="
              login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18 ||
                (this.$storageData.profile.empTypeID == 8 &&
                  login_type == 4)
            "
            class="col-md-2"
            style="width: 12%"
          >
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getPrincipleBranchByPrincipleID()"
            ></multiselect>
          </div>
          <div
            v-if="
              login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18 ||
                (this.$storageData.profile.empTypeID == 8 &&
                  login_type == 4)
            "
            class="col-md-2"
            style="width: 20%"
          >
            <label>Select Principle Branch </label>
            <multiselect
              v-model="principleBranchID"
              :options="principleBranches"
              :show-labels="false"
              label="principleBranchName"
              track-by="principleBranchID"
              @input="getPrincipleCorporateBranches()"
            ></multiselect>
          </div>
          <div
            v-if="
              login_type == 4 ||
                this.$storageData.profile.empTypeID == 7 ||
                this.$storageData.profile.empTypeID == 18 ||
                (this.$storageData.profile.empTypeID == 8 &&
                  login_type == 4)
            "
            class="col-md-2"
            style="width: 20%"
          >
            <label class="form-label" style="font-size: 12px"
              >Select Corporate Branch
            </label>
            <multiselect
              v-model="corpBranchID"
              :options="branches"
              :show-labels="false"
              label="corpBranchName"
              track-by="corpBranchID"
            ></multiselect>
            <!-- @input="onchangeBranch();" -->
          </div>
          <!-- /for principles -->
          <!-- Date range -->
          <div class="col-md-2" style="width: 20%">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              append-to-body
              confirm
              format="DD MMM Y"
              lang="en"
              value-type="format"
              range
              @input="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END-->
          <!-- Payment Type -->
          <div class="col-md-2" style="width: 18%; margin-top: 10px">
            <label>Payment Type </label>
            <multiselect
              v-model="paymentType"
              :options="paymentTypeArr"
              :show-labels="false"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Payment Type END-->
          <!-- Order Type -->
          <div class="col-md-2" style="width: 18%; margin-top: 10px">
            <label>Order Type </label>
            <multiselect
              v-model="orderType"
              :options="orderTypeArr"
              :show-labels="false"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Order Type END-->

          <!-- Apply filter -->
          <div class="col-md-2" style="width: auto; margin-top: auto">
            <button
              class="btn btn-themeOrange mt-4"
              v-on:click="applyFilter(1)"
            >
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width: auto; margin-top: auto">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div
            v-if="visibleDataMessage != ''"
            class="text-left text-danger my-2"
          >
            <strong>{{ visibleDataMessage }}</strong>
          </div>
          <div v-if="isLoading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
            content-class="p-3 text-muted"
            justified
            nav-class="nav-tabs-custom"
          >
            <b-tab active @click="applyFilter(1)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  >Sales Report Overview</span
                >
              </template>

              <div class="row" style="margin: 10px 0 0">
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumGrandTotal
                              ? $func.formatPrice(overViewData.sumGrandTotal)
                              : 0
                          }}
                          <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="(overViewData.sumGrandTotal) ? overViewData.sumGrandTotal : 0"  :duration="2000"></countTo>
                          </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.avgSale
                                ? $func.formatPrice(overViewData.avgSale)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2"></div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumDigitalGrandTotal
                              ? $func.formatPrice(
                                  overViewData.sumDigitalGrandTotal
                                )
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Digital Sales</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalDigitalOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalDigitalOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.digitalAvgSale
                                ? $func.formatPrice(overViewData.digitalAvgSale)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumWallet
                              ? $func.formatPrice(overViewData.sumWallet)
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Wallet</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalWalletOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalWalletOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.walletAvgSale
                                ? $func.formatPrice(overViewData.walletAvgSale)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumVoucher
                              ? $func.formatPrice(overViewData.sumVoucher)
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Voucher</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalVouchersOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalVouchersOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.voucherAvgSale
                                ? $func.formatPrice(overViewData.voucherAvgSale)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumCoupon
                              ? $func.formatPrice(overViewData.sumCoupon)
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Coupon</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalCouponsOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalCouponsOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.couponAvgSale
                                ? $func.formatPrice(overViewData.couponAvgSale)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumTaxes
                              ? $func.formatPrice(overViewData.sumTaxes)
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">GST</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.avgTaxes
                                ? $func.formatPrice(overViewData.avgTaxes)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            overViewData.sumRejectedGrandTotal
                              ? $func.formatPrice(
                                  overViewData.sumRejectedGrandTotal
                                )
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">Rejected Orders</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              overViewData.totalRejectedOrders
                                ? $func.formatCommaSepNum(
                                    overViewData.totalRejectedOrders
                                  )
                                : 0
                            }}
                          </span>
                          Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              overViewData.rejectedAvgSale
                                ? $func.formatPrice(
                                    overViewData.rejectedAvgSale
                                  )
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3 overviewCard">
                  <div class="card">
                    <div class="card-body">
                      <div class="float-end mt-2">
                        <!-- <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart> -->
                      </div>
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{
                            payUSales.totalSum
                              ? $func.formatPrice(payUSales.totalSum)
                              : 0
                          }}
                        </h4>
                        <p class="text-muted mb-0">PayU QR Sales</p>
                      </div>
                      <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                          <span>
                            {{
                              payUSales.totalTransactions
                                ? $func.formatCommaSepNum(
                                    payUSales.totalTransactions
                                  )
                                : 0
                            }}
                          </span>
                          Transactions
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                          <p>
                            {{
                              payUSales.averageSales
                                ? $func.formatPrice(payUSales.averageSales)
                                : 0
                            }}
                          </p>
                          AOV
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Buying Customers</h6>
                      <!-- Bar chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topBuyingCustomers.chartOptions" :series="salesGraph.topBuyingCustomers.series"></apexchart> -->
                      <apexchart
                        v-if="salesGraph.topBuyingCustomers"
                        :options="salesGraph.topBuyingCustomers.chartOptions"
                        :series="salesGraph.topBuyingCustomers.series"
                        class="apex-charts"
                        dir="ltr"
                        height="350"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Selling Restaurant Branches</h6>
                      <!-- Bar chart -->
                      <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topSellingRestaurants.chartOptions" :series="salesGraph.topSellingRestaurants.series"></apexchart> -->
                      <apexchart
                        v-if="salesGraph.topSellingRestaunrants"
                        :options="
                          salesGraph.topSellingRestaunrants.chartOptions
                        "
                        :series="salesGraph.topSellingRestaunrants.series"
                        class="apex-charts"
                        dir="ltr"
                        height="350"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h6 class="mb-4">Sales Graph</h6>
                    <!-- Bar Chart -->
                    <apexchart
                      v-if="salesGraph.salesGraph"
                      :options="salesGraph.salesGraph.chartOptions"
                      :series="salesGraph.salesGraph.series"
                      class="apex-charts"
                      dir="ltr"
                      height="339"
                      type="area"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </b-tab>

            <!-----END of Sales Overview----->
            <b-tab @click="applyFilter(2)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Sales Report List</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div
                        class="col-sm-12 col-md-4"
                        style="margin-bottom: 5px"
                      >
                        <div class="btn-group" role="group">
                          <!-- <download-excel
                          type="button" 
                          class="btn btn-themeBrown"
                          :data="tableData"
                          worksheet="Sales"
                          name="sales Report.xlsx">
                          Excel
                        </download-excel> -->
                          <button
                            v-if="tableItems.length > 0 && $can('download sales report') && (this.$storageData.profile.empTypeID == 2 ||
                                 this.$storageData.profile.empTypeID == 11)"
                            class="btn btn-themeBrown"
                            type="button"
                            @click="validateUser()"
                          >
                           Download Excel
                          </button>
                          <button
                              v-if="tableItems.length > 0 && $can('download sales report')"
                              class="btn btn-themeBrown"
                              type="button"
                              @click="downloadSample()"
                          >
                            Download Excel
                          </button>

                          <div
                            v-if="
                              this.$storageData.profile.empTypeID == 2 ||
                                this.$storageData.profile.empTypeID == 11
                            "
                          >
                            <RestaurantOwnerValidation
                              :active_tab="2"
                              :city="this.city"
                              :company="0"
                              :date="this.daterange"
                              :employee_type="this.$storageData.profile.empTypeID"
                              :order_type="this.orderType"
                              :owner_id="this.$storageData.profile.pid"
                              :payment_type="this.paymentType"
                              :report_type="this.reportType"
                              :restaurant_branch="
                                this.restBranchID
                                  ? this.restBranchID
                                  : this.$storageData.profile.restBranchID
                              "
                              :restaurant_id="
                                this.brandData != ''
                                  ? this.brandData.restID
                                  : this.$storageData.profile.restID
                              "
                            />
                          </div>
                          <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select
                              v-model="perPage"
                              :options="pageOptions"
                              size="sm"
                              style="margin-left: 5px; margin-right: 5px"
                              @input="viewSalesReport()"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <!-- <div class="col-sm-12 col-md-4 row">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div> -->
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :busy="isReportTableBusy"
                        :fields="fields"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :items="tableItems"
                        :per-page="0"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        bordered
                        foot-clone
                        hover
                        outlined
                        responsive
                        striped
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>

                        <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->
                        <template v-slot:cell(recId)="data">
                          <div v-if="login_type == 1">
                            <span> {{ data.item.id }}</span>
                          </div>
                        </template>
                        <template v-slot:cell(orderID)="data">
                          <div
                            v-if="
                              login_type == 3 ||
                                login_type == 4
                            "
                          >
                            <span>
                              {{ data.item.orderID }}
                            </span>
                          </div>
                          <div v-else>
                            <span
                              style="cursor: pointer; color: rgb(243, 118, 106);"
                              @click="viewOrderDetails(data.item.id)"
                            >
                              {{ data.item.orderID }}
                            </span>
                          </div>
                        </template>

                        <template v-slot:cell(employeeName)="data">
                          <span> {{ data.item.userName }}</span>
                        </template>

                        <template v-slot:cell(orderStatus)="data">
                          <span
                            :style="
                              data.item.orderStatus == 'Rejected'
                                ? 'color:#f3766a;'
                                : ''
                            "
                          >
                            {{ data.item.orderStatus }}</span
                          >
                        </template>
                        <template v-slot:cell(payment_gateway)="data">
                          {{ data.item.payment_gateway }}
                        </template>

                        <template v-slot:head(corpSubsidy)>
                          <span> Meal Plan</span>
                        </template>

                        <template v-slot:head(orderStatus)>
                          <span> Ord Status</span>
                        </template>

                        <template v-slot:foot(orderID)>
                          <span>Total: </span>
                        </template>
                        <template v-slot:foot(employeeName)>
                          <span></span>
                        </template>
                        <template v-slot:foot(employeeID)>
                          <span></span>
                        </template>
                        <template v-slot:foot(restaurant)>
                          <span></span>
                        </template>
                        <template v-slot:foot(orderStatus)>
                          <span></span>
                        </template>
                        <template v-slot:foot(payment_gateway)>
                          <span></span>
                        </template>
                        <template v-slot:foot(cancelReason)>
                          <span></span>
                        </template>

                        <template v-slot:foot(itemTotal)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumItemTotal) }}
                          </p>
                        </template>
                        <template v-slot:foot(cpnDisc)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumCpnDisc) }}
                          </p>
                        </template>
                        <template v-slot:foot(otherChrgs)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumOtherChrgs) }}
                          </p>
                        </template>
                        <template v-slot:foot(taxes)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumTaxes) }}
                          </p>
                        </template>
                        <template v-slot:foot(grandTotal)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumGrandTotal) }}
                          </p>
                        </template>
                        <template v-slot:foot(corpSubsidy)>
                          <span></span>
                          <!-- <p class="align-right" style="margin:0;">{{ overViewData.sumCorpSubsidy }}</p> -->
                        </template>
                        <!-- <template v-slot:foot(SubsidyGST)>
                  <p class="align-right" style="margin:0;">{{ overViewData.sumSubsidyGST }}</p>
              </template> -->
                        <template v-slot:foot(wallet)>
                          <p class="align-right" style="margin: 0">
                            {{
                              $func.formatCommaSepNum(overViewData.sumWallet)
                            }}
                          </p>
                        </template>
                        <template v-slot:foot(voucherRedm)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumVoucherRedm) }}
                          </p>
                        </template>
                        <template v-slot:foot(NetPay)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumNetPay) }}
                          </p>
                        </template>
                        <template v-slot:foot(orderType)>
                          <span></span>
                        </template>
                        <template v-slot:foot(paymentType)>
                          <span></span>
                        </template>
                        <template v-slot:foot(date)>
                          <span></span>
                        </template>
                        <template v-slot:foot(rating)>
                          <span></span>
                        </template>
                        <template v-slot:foot(ratingDate)>
                          <span></span>
                        </template>
                        <!-- <template  v-slot:cell(restaurant)="data">
                    <span> {{data.item.restaurant}}</span>
                </template>
                 <template  v-slot:cell(cpnDisc)="data">
                    <span> {{data.item.cpnDisc}}</span>
                </template>
                 <template  v-slot:cell(itemTotal)="data">
                    <span> {{data.item.itemTotal}}</span>
                </template>

                 <template  v-slot:cell(GST)="data">
                    <span v-if="data.item.tax">₹ {{data.item.tax}}</span>
                    <span v-else> 0 </span>
                </template>
                 <template  v-slot:cell(otherCharges)="data">
                    <span v-if="data.item.restCharges">₹ {{data.item.restCharges}}</span>
                     <span v-else> 0 </span>
                </template>
                <template  v-slot:cell(orderTotal)="data">
                    <span>₹ {{data.item.orderTotal}}</span>
                </template>
                 <template  v-slot:cell(walletPoints)="data">
                    <span v-if="data.item.walletDiscount">  {{data.item.walletDiscount}}</span>
                    <span v-else> 0 </span>
                </template>
                <template  v-slot:cell(voucherDiscount)="data">
                    <span v-if="data.item.voucherDiscount"> ₹ {{data.item.voucherDiscount}}</span>
                    <span v-else> 0 </span>
                </template>
                
                <template  v-slot:cell(paidAmount)="data">
                    <span>₹ {{data.item.paidAmount}}</span>
                </template>
                <template  v-slot:cell(corporateSubsidy)="data">
                    <span v-if="data.item.corporateSubsidy"> ₹ {{data.item.corporateSubsidy}}</span>
                    <span v-else> 0 </span>
                </template>
                 <template  v-slot:cell(GSTSubsidy)="data">
                    <span v-if="data.item.GSTSubsidy"> ₹ {{data.item.GSTSubsidy}}</span>
                    <span v-else> 0 </span>
                </template> -->
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <!-- <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                            ></b-pagination> -->
                            <b-pagination
                              v-model="currentPage"
                              :per-page="tableTo"
                              :total-rows="tableTotal"
                              size="md"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderStatusArr="this.orderStatusArr"
      :orderedItemArr="this.orderedItemArr"
      :invoice="this.invoice"
    >
    </OrderReciept>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";
import DatePicker from "vue2-datepicker";
import RestaurantOwnerValidation from "@/components/RestaurantOwnerValidation.vue";

export default {
  page: {
    title: "Sales Mix Report",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    OrderReciept,
    DatePicker,
    RestaurantOwnerValidation
  },
  data() {
    return {
      isReportTableBusy: false,
      sortFlag: "",
      sortingGrapArr: [
        {
          id: 1,
          name: "Value"
        },
        {
          id: 2,
          name: "Volume"
        }
      ],

      tableData: [],
      saleOverview: "",
      tableTotal: "",
      tableTo: "",
      tableItems: [],
      tableExcelData: [],
      tableData2: [],

      title: "Sales Mix Report",
      items: [
        {
          text: "View"
        },
        {
          text: "Sales Mix Report",
          active: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      orderData: [],
      orderDetails: [],
      invoice:[],
      branches: [],
      branchID: "",
      corpBranchID: "",
      restBranchID: this.$storageData.profile.restBranchID,
      principleID: "",
      principleBranchID: "",
      principleBranches: [],
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      overViewData: [],
      payUSales: [],
      salesGraph: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      orderStatusOptions: [
        { statusID: "2", statusName: "Pending" },
        { statusID: "3", statusName: "Accepted" },
        { statusID: "4", statusName: "Preparing" },
        { statusID: "5", statusName: "Prepared" },
        { statusID: "6", statusName: "Dispatched" },
        { statusID: "7", statusName: "Delivered" },
        { statusID: "8", statusName: "Cancelled" }
      ],
      paymentType: "",
      reportType: "sales_report",
      paymentTypeArr: ["Online", "Wallet", "Subsidy", "Pre-buy voucher", "COD"],
      orderTypeArr: [
        "Cafeteria / Food Court",
        "Near by Restaurant",
        // "Free Meal",
        "Event Catering"
        // "Food Court",
      ],
      orderType: "",
      orderStatus: "",
      companyArr: [],
      companyID: 0,
      isLoading: 0,
      visibleDataMessage: "",
      fields: [
        {
          key: "orderID",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "employeeName",
          label: "Customer Name",
          sortable: true
        },
        {
          key: "employeeID",
          label: "Customer ID",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "restaurant",
          sortable: true
        },
        {
          key: "itemTotal",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "cpnDisc",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "otherChrgs",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "taxes",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "grandTotal",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "grandTotal",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "corpSubsidy",
          sortable: true,
          tdClass: "align-right"
        },
        // {
        //     key: "SubsidyGST",
        //     sortable: true,
        //     tdClass: "align-right"
        // },
        {
          key: "wallet",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "voucherRedm",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "NetPay",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "orderType",
          sortable: true
        },
        {
          key: "paymentType",
          sortable: true
        },
        {
          key: "orderStatus",
          sortable: true
        },
        {
          key: "payment_gateway",
          sortable: true
        },
        {
          key: "cancelReason",
          sortable: true
        },
        {
          key: "date",
          sortable: true
        },
        {
          key: "rating",
          sortable: true,
          label: "Rating",
          tdClass: "align-center"
        },
        {
          key: "ratingDate",
          sortable: true,
          label: "Rating Date"
        }
      ],
      login_type:0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableItems.length;
    }
  },
  mounted() {
    this.login_type = this.$storageData.login_type;
    if (this.$storageData.login_type == 1) {
      let idCol = {
        key: "recId",
        label: "ID",
        sortable: true
      };
      this.fields.unshift(idCol);
    }
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.readRestaurantMenu();
    // this.getRestaurantList();

    if (
      this.$storageData.login_type == 1 ||
      this.$storageData.profile.empTypeID == 9
    ) {
      this.getAllCountry();
    } else if (
      this.$storageData.profile.empTypeID == 2 ||
      this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    } else if (this.$storageData.login_type == 4) {
      this.getPrincipleCountry();
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
        .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
        .then(result => {
          this.orderDetails = result.data.data;
          this.orderStatusArr = result.data.data.orderStatus;
          this.orderedItemArr = result.data.data.orderedItemArr;
          this.invoice = result.data.invoice;

        });
    },
    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          // this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {
          restID: this.$storageData.profile.restID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          // this.getAllCompanies();
          this.getRestaurantCities();
        });
    },
    getPrincipleCountry() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {
          principleID: this.$storageData.profile.principleID,
          isDashboard: 1, // for all dropdown in only dashboards
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          // this.country = (this.countryArr) ? this.countryArr[0] : "";
          this.getPrincipleCities();
        });
    },
    getPrincipleCities() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {
          principleID: this.$storageData.profile.principleID,
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          // this.city = (this.cityArr) ? this.cityArr[0] : "";
          this.getPrincipleBranchByPrincipleID();
        });
    },
    getPrincipleBranchByPrincipleID() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          principleID: this.$storageData.profile.principleID,
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
          // this.principleBranchID = this.principleBranches[0];
          // this.principleBranchIDSelected = this.principleBranchID.principleBranchID;
          // console.log(this.principleBranchID);
          // this.getPrincipleCorporateBranches();
        });
    },
    getPrincipleCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCorporateBranches", {
          principleBranchID: this.principleBranchID.principleBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.branches = response.data.data;
          //  this.corpBranchID = (this.branches) ? this.branches[0] : [];
          //  this.principleCorporateStatistics();
        });
    },
    getRestaurantCities() {
      var restID = this.brandData.restID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        restID = this.$storageData.profile.restID;
      }

      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID: restID,
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          // this.city = this.cityArr ? this.cityArr[0] : "";
          // if (
          //   this.$storageData.profile.empTypeID == 11 ||
          //   this.$storageData.profile.empTypeID == 2
          // )
          // {
          //   this.getRestaurantBrandsByCompanyID();
          // }
          if (
            this.$storageData.profile.empTypeID != 11 &&
            this.$storageData.profile.empTypeID != 2
          ) {
            this.readRestaurantBranchData();
          }
        });
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          //this.country = (this.countryArr) ? this.countryArr[0] : "";
          // this.getAllCities();
          if (this.$storageData.login_type == 1) {
            // super admin
            // this.getRestaurantBrands();
            // this.getAllCompanies();
          }
          if (
            this.$storageData.profile.empTypeID == 11 ||
            this.$storageData.profile.empTypeID == 2
          ) {
            // food company login
            // this.getAllCompanies();
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },
    getAllCities() {
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        this.getRestaurantCities();
        return;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;

          if (
            this.$storageData.profile.empTypeID == 11 ||
            this.$storageData.profile.empTypeID == 2
          ) {
            this.getRestaurantBrandsByCompanyID();
          }
        });
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", {
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          companyID: companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.companyArr = response.data.data;
          // if (
          //   this.$storageData.profile.empTypeID == 11 ||
          //   this.$storageData.profile.empTypeID == 2
          // ) {
          //   this.companyID = this.companyArr ? this.companyArr[0] : "";
          // }
          //  this.getProductMixReport();
        });
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(result => {
          this.brandArr = result.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    readRestaurantBranchData() {
      var restBranchID = 0;
      if (this.$storageData.profile.empTypeID == 6) {
        restBranchID = this.$storageData.profile.restBranchID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : "",
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
    },

    viewSalesReport() {
      this.isLoading = 1;

      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      var principleBranchID =
        this.principleBranchID != "" ||
        this.principleBranchID != null ||
        this.principleBranchID != undefined
          ? this.principleBranchID.principleBranchID
          : this.$storageData.profile.principleBranchID &&
            this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : 0;
      var corpBranchID =
        this.corpBranchID != "" && this.corpBranchID != null
          ? this.corpBranchID.corpBranchID
          : 0;
      var companyID = this.companyID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }

      this.isReportTableBusy = true;
      if (this.perPage == "All" && this.$storageData.profile.empTypeID == 1) {
        if (window.confirm("Do you want to download sales data?")) {
          this.axios
            .post(
                this.$loggedRole+"/v2/viewSalesReport?page=1",
              {
                companyID: companyID != "" ? companyID : "",
                corpBranchID: corpBranchID,
                principleID: principleID,
                principleBranchID: principleBranchID,
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.pid,
                restID:
                  this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
                restBranchID: this.restBranchID
                  ? this.restBranchID
                  : this.$storageData.profile.restBranchID,
                daterange: this.daterange,
                paymentType: this.paymentType,
                orderType: this.orderType,
                loginTypeID: this.$storageData.login_type,
                perPage: this.perPage, // new Added
                country: this.country ? this.country.country : "",
                city: this.city ? this.city.city : "",
                princBranchData: this.principleBranches
                  ? this.principleBranches
                  : 0
              },
              {
                responseType: "blob"
              }
            )
            .then(response => {
              this.isLoading = 0;
              this.isReportTableBusy = false;
              this.perPage = 10;
              this.tableTotal = 1;
              this.tableTo = 1;
              this.tableItems = ""; //response.data.data;
              const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel"
                })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "sales-report.xlsx");
              document.body.appendChild(link);
              link.click();
              // this.downloadSample();
            })
            .catch(error => {
              this.isLoading = 0;
              this.isReportTableBusy = false;
              this.visibleDataMessage = "";
              alert(error.response.data.message);
            });
        } else {
          this.isLoading = 0;
          this.isReportTableBusy = false;
          this.perPage = 10;
        }
      } else if (
        this.perPage == "All" &&
        (this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11)
      ) {
        this.isReportTableBusy = false;
        this.isLoading = 0;
        this.perPage = 10;
        this.validateUser();
      } else {
        this.axios
          .post(this.$loggedRole+"/v2/viewSalesReport?page=1", {
            companyID: companyID != "" ? companyID : "",
            corpBranchID: corpBranchID,
            principleID: principleID,
            principleBranchID: principleBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID: this.restBranchID
              ? this.restBranchID
              : this.$storageData.profile.restBranchID,
            daterange: this.daterange,
            paymentType: this.paymentType,
            orderType: this.orderType,
            loginTypeID: this.$storageData.login_type,
            perPage: this.perPage, // new Added
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
            princBranchData: this.principleBranches ? this.principleBranches : 0
          })
          .then(response => {
            this.isLoading = 0;
            this.isReportTableBusy = false;
            this.tableData = response.data;
            this.tableTotal = this.tableData.data.total;
            this.tableTo = this.tableData.data.to;
            this.tableItems = response.data.data.data;
            this.tableExcelData = response.data.data.data;
            this.visibleDataMessage = response.data.visibleDataMessage;
          })
          .catch(error => {
            this.isLoading = 0;
            this.isReportTableBusy = false;
            this.visibleDataMessage = "";
            alert(error.response.data.message);
          });
      }
    },

    viewSalesReportPerPage(currentPage) {
      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      var principleBranchID =
        this.principleBranchID != "" ||
        this.principleBranchID != null ||
        this.principleBranchID != undefined
          ? this.principleBranchID.principleBranchID
          : this.$storageData.profile.principleBranchID &&
            this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : 0;
      var corpBranchID =
        this.corpBranchID != "" && this.corpBranchID != null
          ? this.corpBranchID.corpBranchID
          : 0;
      var companyID = this.companyID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.isReportTableBusy = true;
      this.axios
        .post(this.$loggedRole+"/v2/viewSalesReport?page=" + currentPage, {
          companyID: companyID,
          corpBranchID: corpBranchID,
          principleID: principleID,
          principleBranchID: principleBranchID,
          // empTypeID : this.$storageData.profile.empTypeID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID
            ? this.restBranchID
            : this.$storageData.profile.restBranchID,
          daterange: this.daterange,
          paymentType: this.paymentType,
          orderType: this.orderType,
          loginTypeID: this.$storageData.login_type,
          perPage: this.perPage, // new Added
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          princBranchData: this.principleBranches ? this.principleBranches : 0
        })
        .then(response => {
          //Then injecting the result to datatable parameters.
          this.isReportTableBusy = false;
          if (this.perPage != "All") {
            this.tableItems = response.data.data.data;
            this.tableExcelData = response.data.data.data;
          } else {
            this.tableTotal = 1;
            this.tableTo = 1;
            this.tableItems = response.data.data;
            this.tableExcelData = response.data.data;
          }
          this.visibleDataMessage = response.data.visibleDataMessage;
        })
        .catch(error => {
          this.isLoading = 0;
          this.isReportTableBusy = false;
          this.visibleDataMessage = "";
          alert(error.response.data.message);
        });
    },

    viewSalesReportExcel() {
      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      var principleBranchID =
        this.principleBranchID != "" ||
        this.principleBranchID != null ||
        this.principleBranchID != undefined
          ? this.principleBranchID.principleBranchID
          : this.$storageData.profile.principleBranchID &&
            this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : 0;
      // var corpBranchID = (this.corpBranchID != "") ? this.corpBranchID.corpBranchID : 0;
      var corpBranchID =
        this.corpBranchID != "" && this.corpBranchID != null
          ? this.corpBranchID.corpBranchID
          : 0;
      // alert(corpBranchID);
      this.isReportTableBusy = true;
      var companyID = this.companyID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/viewSalesReportExcel", {
          companyID: companyID,
          corpBranchID: corpBranchID,
          principleID: principleID,
          principleBranchID: principleBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID
            ? this.restBranchID
            : this.$storageData.profile.restBranchID,
          daterange: this.daterange,
          paymentType: this.paymentType,
          orderType: this.orderType,
          loginTypeID: this.$storageData.login_type,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          princBranchData: this.principleBranches ? this.principleBranches : 0
        })
        .then(response => {
          this.tableExcelData = response.data.excelData;
        });
    },
    getOverViewData() {
      this.isLoading = 1;
      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      var principleBranchID =
        this.principleBranchID != "" ||
        this.principleBranchID != null ||
        this.principleBranchID != undefined
          ? this.principleBranchID.principleBranchID
          : this.$storageData.profile.principleBranchID &&
            this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : 0;
      // var corpBranchID = (this.corpBranchID != "") ? this.corpBranchID.corpBranchID : 0;
      var corpBranchID =
        this.corpBranchID != "" && this.corpBranchID != null
          ? this.corpBranchID.corpBranchID
          : 0;
      // alert(corpBranchID);
      var companyID = this.companyID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }

      this.isReportTableBusy = true;
      this.axios
        .post(this.$loggedRole+"/v2/get-sales-overview", {
          companyID: companyID != "" ? companyID : "",
          corpBranchID: corpBranchID,
          principleID: principleID,
          principleBranchID: principleBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID
            ? this.restBranchID
            : this.$storageData.profile.restBranchID,
          daterange: this.daterange,
          paymentType: this.paymentType,
          orderType: this.orderType,
          loginTypeID: this.$storageData.login_type,
          perPage: this.perPage, // new Added
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          princBranchData: this.principleBranches ? this.principleBranches : 0
        })
        .then(res => {
          this.isLoading = 0;
          this.isReportTableBusy = 0;
          this.overViewData = res.data.overViewData;
          this.payUSales = res.data.payuQRSales;
          this.visibleDataMessage = res.data.visibleDataMessage;
        })
        .catch(error => {
          this.isLoading = 0;
          this.isReportTableBusy = false;
          this.visibleDataMessage = "";
          alert(error.response.data.message);
        });
    },
    salesReportGraph() {
      this.isLoading = 1;
      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      // var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID  : 0;
      var principleBranchID =
        this.principleBranchID != "" ||
        this.principleBranchID != null ||
        this.principleBranchID != undefined
          ? this.principleBranchID.principleBranchID
          : this.$storageData.profile.principleBranchID &&
            this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : 0;

      // var corpBranchID = (this.corpBranchID != "") ? this.corpBranchID.corpBranchID : 0;
      var corpBranchID =
        this.corpBranchID != "" && this.corpBranchID != null
          ? this.corpBranchID.corpBranchID
          : 0;
      var companyID = this.companyID;
      if (
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 2
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/v2/salesReportGraph", {
          companyID: companyID,
          corpBranchID: corpBranchID,
          principleID: principleID,
          principleBranchID: principleBranchID,
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID
            ? this.restBranchID
            : this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          daterange: this.daterange,
          paymentType: this.paymentType,
          orderType: this.orderType,
          flag: this.sortFlag.id,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          princBranchData: this.principleBranches
        })
        .then(result => {
          this.isLoading = 0;
          this.salesGraph = result.data.data;
        })
        .catch(error => {
          this.isLoading = 0;
          this.visibleDataMessage = "";
          alert(error.response.data.message);
        });
    },

    validateUser() {
      if (this.city.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select city!"
        });
      } else if (
        this.$storageData.profile.empTypeID == "" ||
        this.$storageData.profile.empTypeID == 0
      ) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Unable to determine user role!"
        });
      } else if (this.$storageData.profile.pid == "" || this.$storageData.profile.pid == 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Invalid user found!"
        });
      } else if (this.restBranchID.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Restaurant branch not found!"
        });
      } else if (this.brandData.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Restaurant brand not found!"
        });
      } else if (this.daterange.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select date!"
        });
      } else {
        this.$bvModal.show("two-factor-validation");
      }
    },
    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableExcelData,
            page: "SalesReport"
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "sales-report.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    applyFilter(tab) {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      if (tab == 1) {
        this.getOverViewData();
        this.salesReportGraph();
      }
      if (tab == 2) {
        this.viewSalesReport();
      }
    },

    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.principleBranchID = "";
      this.brandData = "";
      this.city = "";
      this.cityArr = [];
      this.companyArr = [];
      this.brandArr = [];
      this.branches = [];
      this.branchID = "";
      this.paymentType = "";
      this.orderType = "";
      this.corpBranchID = "";
      this.isLoading = 0;
      // this.viewSalesReport();
      // this.viewSalesReportExcel();
      // this.viewSalesReportByValue(1);
    }
  },
  //new Added
  watch: {
    currentPage: function(newVal) {
      this.viewSalesReportPerPage(newVal);
      //  console.log('Change to page', newVal)
    }
  },
  middleware: "authentication"
};
</script>
<style scoped>
.cardBody {
  min-width: 180px !important;
  width: fit-content !important;
}

.cardTitle {
  font-size: 18px;
}

.cardSubtitle1 span {
  color: #a4827d;
  font-weight: 500;
  font-size: 15.5px;
}

.cardSubtitle2 p {
  color: #f3766a;
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}

.overviewCard {
  padding: 0 5px !important;
}

.multiselect__content-wrapper {
  min-width: 100% !important;
}
</style>
